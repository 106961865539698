import * as $ from 'jquery';
import {initRemoveFieldLinks, formatToPhone, enforceFormat, formatToZipCode} from "./utils";
import 'datatables.net-bs4';
import 'datatables.net-buttons';

let table = null;

$(document).on('turbolinks:load', function() {
    $('.table#users').DataTable({
      order: [0, 'asc'],
      pageLength: 10,
      columnDefs: [
        { targets: 0 },
        { targets: [1,2],
          searchable: false,
          sortable: false,
        }
      ]
    });

    if($('.users.edit') || $('.users.new')){
      initRemoveFieldLinks();
      $('#user_home_phone').keyup(function (e) {
        formatToPhone(e)
      });
      $('#user_home_phone').keydown(enforceFormat);
      $('#user_cell_phone').keyup(function (e) {
        formatToPhone(e)
      });
      $('#user_cell_phone').keydown(enforceFormat);
      $('#user_main_phone').keyup(function (e) {
        formatToPhone(e)
      });
      $('#user_main_phone').keydown(enforceFormat);
      
      $('#user_zip').keyup(function (e) {
        formatToZipCode(e)
      });
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (table) {
        table.destroy();
        table = null;
    }
});