// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import * as $ from "jquery";
import {initRemoveFieldLinks} from "./utils";
import './home';
import './users';
import './mission_fields'
import './missionary_application';
import './conditional-forms';
import './documents';
import '../stylesheets/application.scss';

$(document).on('turbolinks:load', function() {
    $('.alert').find('a.remove-alert').click(function(e) {
        e.preventDefault();
        $(this).parents('.alert').remove();
        return false;
    });
});

window.addFields = function(link, association, content) {
  const newId = new Date().getTime();
  const regexp = new RegExp("new_" + association, "g");
  content = content.replace(regexp, newId);
  content = content.replace(/tmp_uuid/g, newId);
  const { container } = $(link).data();
  if (container) $(container).append(content);
  else $(link).before(content);
  initRemoveFieldLinks();
  $(document).trigger('fields:added');
};

window.deleteResource = function(event, path) {
    event.stopPropagation();
    event.preventDefault();

    if (!window.confirm(
`Deleting this user will delete EVERYTHING associated with this user.

It will delete all character references, missionary applications, file uploads, EVERYTHING.

THIS ACTION CANNOT BE UNDONE. THERE IS NO WAY TO RECOVER THIS INFORMATION IF IT IS DONE ON ACCIDENT.

Are you absolutely, positively certain that you want to delete this user?`)) return;


    const challenge = Math.floor(100000 + Math.random() * 900000).toString();
    const response = window.prompt(
`Last chance: Are you absolutely sure you want to delete this user?
    
THIS ACTION CANNOT BE UNDONE!!!
    
If you want to proceed, please enter the following code in the box below:
    
    ${challenge}
    
    `);

    if (challenge !== response) return;

    $.ajax({
        method: 'delete',
        url: path,
        contentType: 'application/json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        success: () => {
            window.alert('Success!');
        },
        error: (e) => {
            console.error(e);
            window.alert('Something went wrong.');
        }
    });
}

window.reopenApplication = function(event, url) {
    event.preventDefault();
    event.stopPropagation();

    if (!window.confirm('Are you sure you want to reopen this application? This will remove the applicant\'s signature and allow them to make changes.'))
        return;

    console.log('Hello?')

    $.ajax({
        method: 'post',
        url: url,
        success: () => {
            window.alert('Application reopened');
            window.location.href = '/admin/users';
        },
        error: (e) => {
            console.error(e);
            window.alert('Something went wrong.');
        }
    });
}