import * as $ from "jquery";

$(document).on('turbolinks:load', function() {
    const $body = $('body');
    if (!$body.hasClass('mission_fields')) return;

    $('.bool-with-field button.yes-no-button').click(function(e) {
        e.preventDefault();

        const $btn = $(this);
        const $parent = $btn.parent();
        $parent.addClass('selection-made');
        $btn.siblings().removeClass('selected');
        $btn.addClass('selected');

        const $conditional = $parent.find('.conditional-content');
        const $conditionalNo = $parent.find('.conditional-content-no')
        if ($btn.hasClass('yes-button')) {
            $conditional.removeClass('hide');
            $conditional.find('input[type="hidden"]').val('true');
            if($conditionalNo.length) {
                $conditionalNo.addClass('hide');
            }
        } else {
            $conditional.addClass('hide');
            $conditional.find('input[type="hidden"]').val('false');
            if($conditionalNo.length) {
                $conditionalNo.removeClass('hide');
            }
        }
    });

    $('.bool-with-field').each(function() {
        const $this = $(this);
        const hiddenVal = $this.find('.conditional-content input[type="hidden"]').val();

        console.log($this);
        console.log(hiddenVal);
        const $conditionalNo = $this.find('.conditional-content-no')

        if (hiddenVal === 'true') {
            $this.addClass('selection-made');
            $this.find('button.yes-button').addClass('selected');
            $this.find('.conditional-content').removeClass('hide');
            if($conditionalNo.length) {
                $conditionalNo.addClass('hide');
            }
        } else if (hiddenVal === 'false') {
            $this.addClass('selection-made');
            $this.find('button.no-button').addClass('selected');
            $this.find('.conditional-content').addClass('hide');
            if($conditionalNo.length) {
                $conditionalNo.removeClass('hide');
            }
        }
    });
});
