import * as $ from 'jquery';
import { debounce } from 'underscore';

const missionaryCriteria = () => {
    function checkIfSubmittable() {
        const allSelected = $('.missionary-criteria button.yes-button')
            .toArray()
            .every(function(el) {
                // check that every check is either selected or not visible
                return el.getClientRects().length == 0 || $(el).hasClass('selected')
            })

        $('button#accept-all-criteria').prop('disabled', !allSelected);
    }
    const $scrollContainer = $('html, body');
    // $scrollContainer.scrollTop = 0;

    var affirmativeGuidance = debounce(() => window.alert('You must be able to answer "yes" to all questions to be able to proceed. \n\nPlease call us at (505) 248-9849 with any questions.'), 35); // 2frames @ 60FPS
    $('.missionary-criterion button').click(function() {
        const $btn = $(this);
        $btn.parent().addClass('selection-made');
        $btn.siblings().removeClass('selected');
        $btn.addClass('selected');
        $scrollContainer.animate({
            scrollTop: $btn.parent().offset().top - 40
        }, 400, 'swing', () => {
            if ($btn.hasClass('no-button'))
                affirmativeGuidance();
        });

        checkIfSubmittable();
    });
    $("input.hide-next-if-checked").on("click", checkIfSubmittable);
};

const getStarted = () => {
    const $nextBtn = $('button.btn.next-step');
    const $confirmCheck = $('#confirm-check');

    $confirmCheck.click(function() {
        $nextBtn.toggleClass('btn-primary');
    });

    $nextBtn.click(function(e) {

        if (!$confirmCheck.prop('checked')) {
            e.preventDefault();
            window.alert('Please confirm that you have reviewed the PowerPoint presentations before continuing.');
        }
    })

}

$(document).on('turbolinks:load', function() {
    const $body = $('body');
    if (!$body.hasClass('home')) return;

    if ($body.hasClass('missionary_criteria'))
        missionaryCriteria();
    else if ($body.hasClass('get_started'))
        getStarted();
});