import * as $ from 'jquery';
function setUpForm() {
    const showNextIfChecked = function () {
        var $this = $(this);
        var $parent = $this.parents("div.form-group").first();
        var $next = $parent.children(".conditional-render").first();
        
        $next.css("display", this.checked? "": "none");
    };
    const showNextIfNotChecked = function () {
        var $this = $(this);
        var $parent = $this.parents("div.form-group").first();
        var $next = $parent.children(".conditional-render").first();
        
        $next.css("display", this.checked? "none": "");
    };
    const showNextIfValue = function () {
        var $this = $(this);
        var $class = Array.from(this.classList).filter(klass => klass.toLowerCase().indexOf("show-next-if-value-") != -1)[0].split("show-next-if-value-")[1];
        var $parent = $this.parents("div.form-group").first();
        var $next = $parent.children(".conditional-render").first();
        
        $next.css("display", this.value.toLowerCase() == $class? "": "none");
    };
    const showNextRadioIfValue = function () {
        var $this = $(this);
        var $class = Array.from(this.classList).filter(klass => klass.toLowerCase().indexOf("show-next-if-value-") != -1)[0].split("show-next-if-value-")[1];
        var $parent = $this.parents("div.form-group").first();
        var $next = $parent.children(".conditional-render").first();
        
        $next.css("display", this.checked && this.value.toLowerCase() == $class? "": "none");
    };
    const showNextIfNotValue = function () {
        var $this = $(this);
        var $class = Array.from(this.classList).filter(klass => klass.toLowerCase().indexOf("show-next-if-not-value-") != -1)[0].split("show-next-if-not-value-")[1];
        var $parent = $this.parents("div.form-group").first();
        var $next = $parent.children(".conditional-render").first();
        
        $next.css("display", this.value.toLowerCase() != $class? "": "none");
    };
    
    const ifCheckBoxes = $(".show-next-if-checked");
    const ifNotCheckBoxes = $(".show-next-if-not-checked");

    // set up event listeners
    ifCheckBoxes.on("change", showNextIfChecked); 
    ifNotCheckBoxes.on("change", showNextIfNotChecked);

    // trigger default states
    ifCheckBoxes.each(showNextIfChecked);
    ifNotCheckBoxes.each(showNextIfNotChecked);

    const ifSelect = $("select.show-next-if-value");
    ifSelect.each(showNextIfValue).on("change", showNextIfValue);
    $("input[type=radio].show-next-if-value").on("change", showNextRadioIfValue);
    $("input[type=radio].show-next-if-value:checked").each(showNextRadioIfValue)
    const ifNotSelect = $("select.show-next-if-not-value");
    ifNotSelect.each(showNextIfNotValue).on("change", showNextIfNotValue);

    var confirmCache = {};
    var confirmChange = function(e) {
        const $field = $(e.target);
        const label = $field.parents("div.form-group").first().children("label").first();
        const confirmMessage = $field.data("confirm");
        let confirmPrompt = "Are you sure you want to set "+ label.text() + " to " + $field.val() + "?";
        if (confirmMessage) {
            confirmPrompt += "\n\n" + confirmMessage;
        }
        if(confirm(confirmPrompt)) {
            confirmCache[$field.attr("name")] = $field.val();
            return true;
        } else {
            $field.val(confirmCache[field.attr("name")]);
            e.preventDefault();
            return false;
        }
    }
    $(".confirm input").on("change", confirmChange).each(function() { confirmCache[this.name] = this.value; })
    $("input[type=checkbox].readonly").on("click", function(e) { e.preventDefault() });
}
$(document).on('turbolinks:load', setUpForm);