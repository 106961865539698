import * as $ from 'jquery';
import ClipboardJS from 'clipboard';

function setUpForm() {
    const $form = $(".edit_missionary_application");
    window.addEventListener("beforeunload", function (e) {
        var forms = Array.from($form.children("form"));
        var safe = forms.every(form =>!("dirty" in form) || form.dirty == false);
        if (safe || forms.length === 0) { 
            delete e['returnValue'];
            return;
        } else {
            e.returnValue = 'You have changes that will be lost if you leave this page now. Are you sure you want to leave this page?';
            e.preventDefault();
            return "You have changes that will be lost if you leave this page now. Are you sure you want to leave this page?";
        }
    });
    
    const method = $form.children("[name=_method]").first();
    const jsonForField=(field, value = null) => {
        const name = field.name;
        const parts = name.split(/[\[|\]]/).filter(v=>v).reverse();
        return parts.reduce((blob, key) => {
            const blobNew = {};
            blobNew[key] = blob;
            return blobNew;
        }, value);
    }
    const cachedValues = {};
    var saveOnBlur = function(event) {
        const field = event.target;
        const value = field.type == "checkbox"? field.checked: field.value;
        if(cachedValues[field.name] === value) return;

        $(field).parent().addClass("was-validated");
        
        cachedValues[field.name] = value;
        $.ajax({
            method: method.val(),
            url: $form.attr("action"),
            contentType: "application/json",
            headers: {
                'Accept': "application/json",
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(jsonForField(field, value)),
            success: function (w) {
                field.form.dirty = false;
                w;
                const fieldName = field.name.match(/\[(.*)\]/)[1];
                const error = w.errors[fieldName];
                error;
                if(error) {
                    $(field).removeClass('is-valid').addClass("is-invalid");
                } else {
                    $(field).addClass('is-valid').removeClass("is-invalid");
                }
            }
        });
    }

    $form.children("input").on("keypress", function(e) {
        e.target.form.dirty = true;
    })
    
    $(".autosave input, .autosave textarea, .autosave select").on("blur", saveOnBlur).on("change", saveOnBlur);

    let chain = Promise.resolve();

    var submitReference = function(event) {
        const field = event.target;
        const value = field.type == "checkbox"? field.checked: field.value;
        const $form = $(field.form);
        const fieldName = field.form.action + field.name;
        if(cachedValues[fieldName] === value) return;

        $(field).parent().addClass("was-validated");
        
        cachedValues[fieldName] = value;

        chain = chain.then(() => new Promise(done => { $.ajax({
            method: method.val(),
            url: $form.attr("action"),
            headers: {
                'Accept': "application/json",
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ 
                character_reference: $form
                    .serializeArray()
                    .map(r => ({
                        segments: r.name.split(/\[|\]/g).filter(v=>v),
                        value: r.value
                    }))
                    .filter(r => r.segments.length > 1)
                    .map(r => ({
                        field: r.segments.reverse()[0],
                        value: r.value
                    }))
                    .reduce((json,r)=>({
                        ...json,
                        ...{
                            [r.field]:r.value
                        }
                    }), {})
            }),
            success: function () {
                $form.dirty = false;
                done();
            }
        }) }));
    }
    

    $(".submit-full-form input").on("change", submitReference).on("blur", submitReference);

    // Javascript to enable link to tab
    var url = document.location.toString();
    if(url.includes("show_validation_errors=true")) {
        const $field = $(".is-invalid, .form-control:invalid, .was-validated [required][value='']").first();
        const $tab = $field.parents("[role=tabpanel]");
        try {
            const tab = $tab[0].id;
            setTimeout(function() {
                $('.nav-pills a[href="#' + tab + '"]').tab('show');
            }, 500);
        } catch (e) {
            e;
        }
    } else {
        const lastTab = localStorage.getItem("full-missionary-application-tab");
        if (url.match('#')) {
            setTimeout(function() {
                $('.nav-pills a[href="#' + url.split('#')[1] + '"]').tab('show');
            }, 500);
        } else if (lastTab) {
            setTimeout(function() {
                $('.nav-pills a[href="' + lastTab + '"]').tab('show');
            }, 500);
        }
    }

    $('.nav-pills a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash; // Change hash for page-reload
        window.localStorage.setItem("full-missionary-application-tab", window.location.hash);
    });

    const $autocompletecontainer = $(".autocompleteoptions");
    $autocompletecontainer.each(function () {
        const options = $(this).children("button");
        const $field = $(`#${this.attributes["aria-labelledby"].value}`);
        options.on("click", function() {
            $field.val($(this).html());
        })
    })
}

function setUpClipboard() {
    var clip = new ClipboardJS('.btn.clipboard');
    var btn = $('.btn.clipboard').tooltip({
        trigger: 'click'
    })

    $('.btn.clipboard').on('click', function(e){
        e.preventDefault();
        clip.on('success', function(){
            btn.tooltip('show');
            setTimeout(function() {
                btn.tooltip('hide');
              }, 500);
        });
    });
}

$(document).on('turbolinks:load', function(){
    setUpForm();
    setUpClipboard();

    $('#missionary_application_user_attributes_marital_status').on('change', function() {
        if ($(this).find(':selected').text() == "Married" ) {
            const user_id = $('#missionary_application_user_attributes_id').val();
            $.ajax({
                method: 'PATCH',
                url: '/admin/users/' + user_id + '/update_married',
                headers: { Accept: 'application/json' },
                data: {
                    user: {
                        spouse_name: '<unknown>',
                        spouse_dob: '1970-01-01',
                        marriage_date: '1970-01-01',
                    },
                },
                success: function() {},
                error: function() {}
            });
        }
    });
});